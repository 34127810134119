<template>
  <event-list :events="events"></event-list>
</template>

<script>
import useEvent from '@/use/useEvent.js'
import EventList from '@/components/EventList.vue'

export default {
  components: {
    EventList
  },
  setup() {
    const { events } = useEvent()
    return {
      events
    }
  }
}
</script>
